.spin {
  margin: 0px auto;
}

.spin h1 {
  width: 100%;
}

.spin .top {
  display: flex;
  flex-direction: row;
}

.spin .container {
  width: 90%;
}

/** How **/
.how-info {
  flex-direction: column;
}

.spin .how-step-stack {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
  font-family: ''Aktiv Grotesk'';
  max-width: 900px;
}

.spin .how-step-stack.center {
  margin: 60px auto 40px;
}

.spin .how-image {
  flex-direction: row;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.spin .how-title {
  font-size: 15px;
  font-weight: 700;
}

.spin .how-desc {
  font-size: 14px;
  font-weight: 300;
}

/** Customization Section **/
.spin .add-on-block {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.spin .add-on-title {
  font-size: 18px;
  color: #d11f34;
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
  text-align: left;
}

.spin .add-on-description {
  font-weight: 300;
  font-size: 15px;
  color: black;
  font-family: 'Gellix';
  font-style: normal;
  text-align: left;
  margin-top: 5px;
}

.spin .add-on-option {
  margin-right: 20px;
  min-width: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  font-family: 'Gellix';
  font-weight: 900;
  background-color: #f4f4f4;
  border: #d11f34 3px solid !important;
  padding: 17px 20px;
  font-size: 18px;
  color: #d11f34;
}

.spin .add-on-option.selected,
.spin .add-on-option.active {
  background-color: #d11f34;
  border: 0px;
  color: white;
}

.spin .add-on-option:hover {
  cursor: pointer;
  opacity: .8;
}

/** Elimination Section **/
.spin .elimination-title {
  font-size: 18px;
  color: #d11f34;
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: 20px 0px;
}

.spin .section-name .elimination-title {
  text-align: center;
  margin-bottom: -10px;
}

.spin .family-friendly-elimination-title {
  padding-bottom: 15px;
}

.spin .spin-options {
  margin: 20px auto 60px;
  max-width: 925px;
}

.spin .spin-options p {
  font-weight: 100;
  font-size: 15px;
}

.spin .spin-options .button-wrapper {
  display: flex;
  margin: 0px auto;
}

.spin .family-sold-out,
.spin .eliminate .disclaimer {
  font-weight: 300;
  font-size: 15px;
  text-align: justify;
  text-align-last: center;
  padding: 20px 0px;
  width: 80%;
  margin: 0px auto;
  color: black;
  font-family: 'Gellix';
  font-style: normal;
}

.spin .disclaimer.covid-questions {
  text-decoration: underline;
}

.spin .disclaimer.covid-questions:hover {
  opacity: 0.8;
  cursor: pointer;
}

.spin .eliminate .disclaimer.safari {
  text-align: center;
}

.spin .eliminate .disclaimer.two-part {
  width: 100%;
  font-size: 14px;
}

.spin .eliminate .label {
  font-size : 12px;
  margin-bottom : -10px;
}

.spin .spin-type,
.spin .show-elimination,
.spin .show-preference {
  margin: 30px auto;
  padding: 20px 10px;
  width: 100%;
  background-color: #f4f4f4;
  text-align: center;
}

.spin .spin-type .family-roulette-disabled {
  width: 65%;
  margin: 0px auto;
}

.spin .show-columns {
  margin: auto;
}

.spin .shows {
  width: 250px;
  margin: 0 25px;
  display: inline-block;
  letter-spacing: 1.2px;
  cursor: pointer;
  vertical-align: top;
}

.spin .shows p {
  margin: 20px auto;
  transition: all 300ms ease;
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Aktiv Grotesk';
}

.spin .shows p:hover {
  opacity: .7;
}

.spin .button-wrapper {
  margin: 0px auto;
  display: flex;
  align-items: stretch;
  width: 90%;
}

.spin .roulette-option {
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  font-family: 'Gellix';
  font-weight: 900;
  background-color: #f4f4f4;
  border: #d11f34 3px solid !important;
  border-radius: 10px;
  padding: 17px 20px;
  font-size: 18px;
  color: #d11f34;
}

.spin .show-preference .button-wrapper {
  width: 60%;
}

.spin .show-preference .button-wrapper.special {
  width: 90%;
}

.spin .show-preference .roulette-option {
  width: 250px;
}

.spin .show-preference .roulette-option-text {
  justify-content: center;
  align-items: center;
}

.spin .roulette-option.selected,
.spin .roulette-option.active {
  background-color: #d11f34;
  border: 0px;
  color: white;
}

.spin .roulette-option:hover {
  cursor: pointer;
  opacity: .8;
}

.spin .family-friendly-discount {
  font-size: 13px;
  padding: 10px 0px;
  font-family: 'Aktiv Grotesk';
}

/* Calendar */
.spin .react-calendar {
  background-color: #f5f5f5;
  border: 0px;
  padding: 25px;
  width: 500px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.spin .react-calendar__navigation {
  margin: 10px 0px 25px 0px;
}

.spin .react-calendar__month-view {
  margin: 0px auto;
}
.spin .react-calendar__tile {
  margin: 2.5px;
  max-width: 13% !important;
  height: 52px;
  background-color: #e6e6e6;
  font-family: 'Aktiv Grotesk';
  font-size: 21px !important;
  font-weight: 200;
  line-height: 12px;
  color: black;
  border-radius: 4px;
}

.spin .react-calendar__tile:enabled:hover {
  background-color: #d51f36;
  opacity: 0.8;
  color: white;
}

.spin .react-calendar__tile--active,
.spin .react-calendar__tile--active:enabled:focus {
  background-color: #d51f36;
  color: white;
}

.spin .react-calendar__tile:disabled {
  color: #a8a8a8 !important;
  background-color: #e6e6e6;
}

.spin .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.spin .react-calendar .react-calendar__month-view__days__day--neighboringMonth abbr,
.spin .react-calendar__tile.sold-out abbr {
  display: none;
}

.spin .react-calendar__tile.subscription-friendly {
  -webkit-box-shadow: 0 0 10px 1px #d4af37 inset;
  -moz-box-shadow: 0 0 10px 1px #d4af37 inset;
  box-shadow: 0 0 10px 1px #d4af37 inset;
}

.spin .react-calendar__tile.subscription-weekend:hover > abbr {
  display: none;
}

.spin .react-calendar__tile.subscription-weekend:hover {
  padding: 0;
}

.spin .react-calendar__tile.subscription-weekend:hover:after {
  content: '$47 day';
  font-size: 18px;
  line-height: 18px;
}

.spin .react-calendar__tile.subscription-weekend {
  -webkit-box-shadow: 0 0 10px 1px #89CFF0 inset;
  -moz-box-shadow: 0 0 10px 1px #89CFF0 inset;
  box-shadow: 0 0 10px 1px #89CFF0 inset;
}

.spin .react-calendar__tile:disabled,
.spin .cyber-date.react-calendar__month-view__days__day--neighboringMonth,
.spin .subscription-friendly.react-calendar__month-view__days__day--neighboringMonth,
.spin .subscription-weekend.react-calendar__month-view__days__day--neighboringMonth {
  -webkit-box-shadow: 0 0 0 0 #d4af37 inset;
  -moz-box-shadow: 0 0 0 0 #d4af37 inset;
  box-shadow: 0 0 0 0 #d4af37 inset !important;
}

.spin .react-calendar__month-view__weekdays__weekday:after {
  content: none;
}

.spin .react-calendar__tile.sold-out:not(.react-calendar__month-view__days__day--neighboringMonth)::after {
  content: "";
  font-size: 13px;
  color: #949494;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0px !important;
}

.spin .react-calendar__month-view__days__day--weekend {
  color: black;
}

.spin .react-calendar .react-calendar__month-view__weekdays__weekday:after {
  content: '$49';
  font-weight: 400;
  display: block;
  line-height: 4px;
  margin: 9px auto;
}

.spin .react-calendar.subscriber .react-calendar__month-view__weekdays__weekday:after {
  content: '$47';
}

.spin .react-calendar .react-calendar__month-view__weekdays__weekday:first-child:after,
.spin .react-calendar .react-calendar__month-view__weekdays__weekday:nth-child(6):after,
.spin .react-calendar .react-calendar__month-view__weekdays__weekday:last-child:after {
  content: '$59';
  font-weight: 400;
}

.spin .react-calendar__month-view__days__day--neighboringMonth time {
  display: none;
}

.spin .react-calendar__tile.sold-out {
  padding: 0px !important;
}

.spin .react-calendar__tile.sold-out time {
  display: none;
}

.spin .react-calendar__navigation__label,
.spin .react-calendar__navigation__arrow {
  color: #d51f36;
  font-weight: 700;
  font-family: 'Gellix';
  font-size: 26px;
  background-color: #f5f5f5 !important;
  text-transform: uppercase;
}

.spin .react-calendar__navigation__prev2-button {
  display: none;
}

.spin .react-calendar__navigation__next2-button {
  display: none;
}

.spin .react-calendar__month-view__weekdays {
  text-align: left;
  font-size: 13px;
  font-family: 'Aktiv Grotesk';
  margin-bottom: -8px;
}

.spin .pseudo-link {
  text-decoration: underline;
}

.spin .no-fee-calendar {
  font-family: 'Aktiv Grotesk';
  font-size: 13px;
  padding: 20px;
}

.spin .no-fee-calendar:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* Forms */
.form.date-time {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.spin .order-date {
  letter-spacing: 0px;
  color: #d51f36;
  font-family: 'Aktiv Grotesk';
  font-weight: 700;
  padding-top: 10px;
}

.spin .date-quantity {
  padding-top: 10px;
}

.tickets-select {
  margin-top: -1px;
}

.tickets-select .MuiInputBase-input {
  padding-top: 10px !important;
  background-color: #f4f4f4 !important;
  color: #d11f34;
  padding: 10px 30px;
  margin-top: 25px;
  border-radius: 4px;
}

.tickets-select .MuiSelect-icon {
  top: calc(70% - 12px);
}

.form .contact-field.show-time .MuiTypography-body1 {
  font-family: 'Aktiv Grotesk' !important;
  text-transform: none;
}

.form.flex-item.input-fields {
  margin-left: 60px;
}

.MuiInputBase-input {
  font-family: 'Aktiv Grotesk';
  font-weight: 400;
  padding-top: 30px;
}

.MuiInputLabel-shrink.form-label {
  transform: translate(0, 1.5px) scale(1);
}

.input-field {
  width: 86%;
}

.input-field.code {
  padding: 5px 0px;
}

.input-field.code .MuiInputBase-input {
  font-family: 'Gellix' !important;
  letter-spacing: 1px !important;
  font-size: 12px !important;
  font-weight: 500;
  margin: -10px 0px -5px;
}

.spin .confirm-email-spacer {
  margin: 20px 0px;
}

.spin .input-field .MuiInput-input {
  font-family: 'Aktiv Grotesk';
  font-weight: 500;
  padding-top: 30px;
}

.input-field.radio-group {
  width: 120% !important;
}

.input-field .radio-input .MuiFormControlLabel-label {
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
}

.spin .radio-input.subscription-friendly .MuiIconButton-label {
  -webkit-box-shadow: 0 0 7px 1px #d4af37;
  -moz-box-shadow: 0 0 7px 1px #d4af37;
  box-shadow: 0 0 7px 1px #d4af37;
}

.spin .radio-input.subscription-weekend .MuiIconButton-label {
  -webkit-box-shadow: 0 0 7px 1px #89CFF0;
  -moz-box-shadow: 0 0 7px 1px #89CFF0;
  box-shadow: 0 0 7px 1px #89CFF0;
}

.account-warning {
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-weight: 200;
}

.MuiSelect-selectMenu {
  background-color: white !important;
  margin-top: 20px;
}

/* Checkout */
.spin-payment {
  background-color: #f4f4f4;
  width: 100%;
  margin: 0px auto;
}

.user-info-mobile {
  margin: 0px auto 40px;
  background-color: #f4f4f4;
  width: 100%;
}

.user-info-mobile .content-container {
  width: 80%;
  margin: 0px auto;
  padding: 0px 0px 40px 10px;
}

.checkbox-wrapper {
  display: flex;
  font-size: 12px;
  letter-spacing: 0px;
  margin-bottom: 20px;
}

.holiday-fee:hover,
.checkbox-wrapper:hover {
  opacity: .8;
  cursor: pointer;
}

.holiday-fee,
.checkbox-wrapper .checkbox-text {
  text-decoration: underline;
}

.checkbox-wrapper .checkbox {
  border: 2px solid #d11f34;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.checkbox-wrapper .checkbox.checked:before {
  content: '\2713';
  position: absolute;
  top: -15px;
  left: -2px;
  font-size: 22px;
  color: #d11f34;
}

.checkbox-wrapper.mailing-list-wrapper {
  margin-top: -10px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.spin-payment .content-container {
  background-color: #f4f4f4;
  padding: 25px 25px 20px 0px;
}

.spin-payment .checkout {
  margin-top: -30px;
  padding: 50px 15px 0px 120px;
  width: 57%;
}

.spin-payment .review {
  border-right: 2px solid #868686;
  font-weight: 700;
  padding-left: 70px;
  width: 43%;
}

.user-info-mobile .content-container h3,
.spin-payment .review h3 {
  margin-top: -8px;
  padding-top: 50px;
}

.spin-payment .review .label {
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin: 25px 0px 20px 0px;
}

.spin-payment .review .field {
  font-weight: 400;
  font-family: 'Aktiv Grotesk';
  margin: -15px 0px -10px 0px;
}

.spin-payment .price-detail {
  font-weight: 300;
  font-family: 'Aktiv Grotesk';
  padding: 3px 0px;
  font-size: 12px;
}

.spin-payment .points-earned {
  margin: -15px 0px 15px;
}

.checkout h4 {
  margin-top: -20px;
}

::placeholder {
  color: #cccccc;
  opacity: 1;
}

/* Popups */
.popup-content {
  padding: 40px;
  width: 100%;
  text-align: center;
  font-family: 'Aktiv Grotesk';
}

.popup-content h2 {
  font-size: 22px;
}

.popup-content h3 {
  font-size: 18px;
  text-transform: none;
}

.popup-content h4 {
  text-transform: none;
}

.popup-content a {
  color: #d11f34 !important;
}

.popup-content li {
  padding-bottom: 10px;
}

.popup-content.ticket-flex p {
  text-align: left;
}

.popup-content .questions-paragraph,
.popup-content .restaurant-paragraph,
.popup-content.special-popup {
  text-align: justify;
  text-align-last: center;
}

.popup-content .restaurant-paragraph.safari,
.popup-content.special-popup.safari {
  text-align: center;
}

.popup-buttons {
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.popup-buttons .cta-button {
  margin: 15px auto;
  text-transform: none;
  width: 45%;
  padding: 15px 20px;
}

.popup-buttons .cta-button-no {
  background-color: gray;
}

.popup-buttons .ok-button {
  width: 90%;
}

@media (max-width: 1000px) {
  .spin .static-page {
    width: 95%;
  }

  .spin .container {
    width: 100%;
  }

  .spin .top {
    flex-direction: column;
  }

  .spin .form.flex-item.input-fields {
    margin: 0px auto;
    width: 100%;
    max-width: 500px;
  }

  .spin .spin-description {
    margin-bottom: 20px;
  }

  .spin .ticketing {
    padding: 20px 10px 0px 10px;
  }

  .spin .react-calendar {
    margin: 0px auto;
  }

  .spin .form {
   padding-left: 0px;
  }

  .spin .form.date-time {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .spin .order-date,
  .input-field .radio-input .MuiFormControlLabel-label {
    font-size: 13px !important;
  }

  .spin .spin-type .family-roulette-disabled {
    width: 100%;
  }

  .paragraph-header {
    font-family: 'Aktiv Grotesk';
    padding-top: 5px;
  }

  .spin .button-wrapper {
    flex-direction: column;
  }

  .spin .roulette-option {
    width: 200px;
    font-size: 16px;
  }

  .spin .eliminate .disclaimer {
    width: 80%;
  }

  .spin .show-preference .button-wrapper {
    width: 100%;
  }

  .spin .show-preference .button-wrapper.special {
    width: 100%;
  }

  .spin .input-field {
    width: 100%;
  }

  .spin .eliminate .disclaimer.two-part {
    width: 80%;
  }

  .spin .shows {
    display: block;
    margin: 0px auto;
  }

  .spin .payment {
    margin-top: 75px;
    padding: 0px;
  }

  .spin-payment {
    width: 100%;
  }

  .spin .section-name h3 {
    padding-top: 40px;
  }

  .spin-payment .content-container {
    display: block;
    padding: 0px;
  }

  .spin-payment .review {
    border-right: 0px;
    border-bottom: 2px solid #868686;
    text-align: left;
    padding: 0px 0px 40px 10px;
    width: 80%;
    margin: 0px auto;
  }

  .spin-payment .review h3 {
    padding-top: 40px;
  }

  .spin-payment .checkout {
    width: 80%;
    margin: 0px auto;
    padding: 20px 0px;
  }

  .spin .checkout .total {
    padding-bottom: 8px;
  }

  .input-field.code {
    width: 100%;
  }

  .spin-payment .review .label {
    font-size: 14px;
  }

  .spin-payment .review .field {
    font-size: 14px;
  }

  .checkbox-wrapper {
    width: 100%;
    padding-top: 10px;
  }

  .checkbox-wrapper .checkbox {
    position: absolute;
    margin-right: 10px;
  }

  .checkbox-wrapper .checkbox-text {
    padding-left: 10%;
    text-align: left;
  }

  .react-calendar {
    width: 100%;
  }

  .react-calendar__navigation {
    padding: 0px;
  }

  .react-calendar__navigation__label {
    font-size: 16px;
    margin-top: 3% !important;
  }

  .react-calendar__month-view__weekdays {
    font-size: 10px;
  }

  .react-calendar__month-view__weekdays__weekday {
    flex-basis: 15.5% !important;
    font-size: 14px !important;
    max-width: 100% !important;
  }

  .popup-content h2 {
    margin-top: 20px;
  }

  .popup-content .cta-button {
    font-size: 14px;
    margin: 5px;
    padding: 5px;
    width: 100%;
  }

  .popup-content .popup-buttons {
    padding: 0px 0px;
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .spin .how-step-stack {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    margin: 40px auto 40px;
    width: 100%;
  }

  .spin .how-step-stack.center {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    width: 74%;
    margin: 40px auto 40px;
    padding-left: 0px;
  }

  .spin .how-image {
    width: 60px;
    height: 60px;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .spin .how-title {
    font-size: 15px;
    padding-bottom: 2px;
  }

  .spin .how-desc {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .spin .spin-options {
    margin-top: 40px;
  }

  .spin .react-calendar__tile.sold-out:not(.react-calendar__month-view__days__day--neighboringMonth)::after {
    font-size: 8px;
  }

  .spin .react-calendar__month-view__weekdays__weekday {
    flex-basis: 13.8% !important;
    font-size: 11px !important;
    max-width: 100% !important;
    line-height: 5px;
  }

  .spin .react-calendar__tile {
    flex-basis: 12% !important;
    font-size: 12px !important;
    line-height: 10px;
    height: 12%;
    min-height: 30px;
  }

  .spin .spin-options .button-wrapper {
    display: block;
  }

  .spin .react-calendar__navigation__label,
  .spin .react-calendar__navigation__arrow {
    font-size: 16px;
  }
}
